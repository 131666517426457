import axios from "axios";
let url;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  url = process.env.REACT_APP_USERS_URL_DEV;
} else {
  // production code
  url = process.env.REACT_APP_USERS_URL_PROD;
}

console.log(process.env.NODE_ENV);

const customAxios = axios.create({
  baseURL: url,
});

// Add a request interceptor
customAxios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
customAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status == 403 || error.response.status == 401) {
      logoutUser();
    }
    return Promise.reject(error);
  }
);

const logoutUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("username");
  window.location.reload();
};

const api = {
  isAuthenticated: async () => {
    const { data } = await customAxios.get("/auth");
    if (data.canPass) {
      return true;
    } else {
      logoutUser();
      return false;
    }
  },

  getRequest: async (route) => {
    const { data } = await customAxios.get(route, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
      },
    });
    return data;
  },

  postRequest: async (route, body) => {
    const { data } = await customAxios.post(route, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
      },
    });
    return data;
  },

  deleteRequest: async (route) => {
    const { data } = await customAxios.delete(route, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
      },
    });
    return data;
  },
};

export default api;
