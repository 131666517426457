import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const drawerPages = [
  {
    name: "Home",
    opened: false,
  },
  {
    name: "Clients",
    opened: false,
  },
  {
    name: "OTA",
    opened: false,
  },
  {
    name: "Users",
    opened: false,
  },
  {
    name: "Tools",
    opened: false,
  },
  {
    name: "Settings",
    opened: false,
  },
  {
    name: "Help & Feedback",
    opened: false,
  },
  {
    name: "Log out",
    opened: false,
  },
];

export const appSlice = createSlice({
  name: "app",
  initialState: {
    version: process.env.REACT_APP_APP_VERSION,
    user: {
      token: localStorage.getItem("token") || null,
      username: localStorage.getItem("username") || null,
    },
    module: {
      name: "Cosmo Admin",
    },
    drawerPages,
    theme:
      localStorage.getItem("currentTheme") || "material.purple.light.compact",
    logo: null,
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    setModule: (state, action) => {
      state.module = action.payload.module;
    },
    setDrawerPage: (state, action) => {
      state.drawerPages = state.drawerPages.map((drawerPage, index) =>
        index == action.payload.index
          ? { ...drawerPage, opened: action.payload.opened }
          : drawerPage
      );
      //state.drawerPages = action.payload.drawerPages;
    },
    resetDrawerPages: (state) => {
      state.drawerPages = drawerPages;
    },

    setTheme: (state, action) => {
      state.theme = action.payload.name;
    },
    setLogo: (state, action) => {
      state.logo = action.payload.logo;
    },
  },
});

export const {
  setUser,
  setModule,
  setDrawerPage,
  resetDrawerPages,
  setTheme,
  setLogo,
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectVersion = (state) => state.app.version;
export const selectUser = (state) => state.app.user;
export const selectModule = (state) => state.app.module;
export const selectDrawerPages = (state) => state.app.drawerPages;
export const selectTheme = (state) => state.app.theme;
export const selectLogo = (state) => state.app.logo;

export default appSlice.reducer;
