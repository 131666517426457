import { exportClientRows } from "@denisferatovic/ota-client-library";
import { exportUsersRows } from "@denisferatovic/ota-users-library";

import {
  showDetailsFileManager,
  showThumbnailsFileManager,
} from "@denisferatovic/ota-library";
export const addMenuOptions = [
  { id: 1, text: "Text Document" },
  { id: 2, text: "RTF Document" },
  { id: 3, text: "Spreadsheet" },
];
export const exportMenuOptions = (page) => {
  const a = [
    {
      icon: "export",
      items: [
        {
          text: "Export all data",
          onClick: (e) => {
            page == "client" ? exportClientRows(false) : exportUsersRows(false);
          },
        },
        {
          text: "Export selected data",
          onClick: (e) => {
            page == "client" ? exportClientRows(true) : exportUsersRows(true);
          },
        },
      ],
    },
  ];
  return a;
};

export const detailsLayoutMenuOptions = [
  {
    id: 1,
    icon: "detailslayout",
    onClick: () => {
      showDetailsFileManager();
    },
  },
  {
    id: 2,
    icon: "smalliconslayout",
    onClick: () => {
      showThumbnailsFileManager();
    },
  },
];
