import { Switch } from "devextreme-react";
import themes from "devextreme/ui/themes";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTheme,
  selectVersion,
  setLogo,
  setTheme,
} from "../features/appSlice";
import CosmoLogoWhite from "../assets/Cosmo-Logo_White.png";
import CosmoLogoBlack from "../assets/Cosmo-Logo_Black.png";

const Settings = () => {
  const APP_VERSION = useSelector(selectVersion);
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();
  const onValueChanged = (e) => {
    let chosenTheme = e.value
      ? "material.purple.dark.compact"
      : "material.purple.light.compact";
    localStorage.setItem("currentTheme", chosenTheme);
    themes.current(chosenTheme);
    dispatch(setTheme({ name: chosenTheme }));
    if (chosenTheme == "material.purple.dark.compact") {
      dispatch(setLogo({ logo: CosmoLogoWhite }));
    } else {
      dispatch(setLogo({ logo: CosmoLogoBlack }));
    }
  };

  return (
    <div className="dx-card card-padding">
      <p className="card-title">Settings</p>
      <div className="card-row border-line">
        <p>Dark mode</p>
        <Switch
          onValueChanged={onValueChanged}
          defaultValue={theme == "material.purple.dark.compact" ? true : false}
        />
      </div>
      <div className="card-row border-line">
        <p>Version</p>
        <p>{APP_VERSION}</p>
      </div>
    </div>
  );
};

export default Settings;
