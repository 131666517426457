const init = () => {
  let OTA_URL;
  let OTA_ROOT;
  let CLIENT_URL;
  let USERS_URL;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // dev code
    OTA_URL = process.env.REACT_APP_OTA_URL_DEV;
    OTA_ROOT = process.env.REACT_APP_OTA_ROOT_DEV;
    CLIENT_URL = process.env.REACT_APP_CLIENT_URL_DEV;
    USERS_URL = process.env.REACT_APP_USERS_URL_DEV;
  } else {
    // production code
    OTA_URL = process.env.REACT_APP_OTA_URL_PROD;
    OTA_ROOT = process.env.REACT_APP_OTA_ROOT_PROD;
    CLIENT_URL = process.env.REACT_APP_CLIENT_URL_PROD;
    USERS_URL = process.env.REACT_APP_USERS_URL_PROD;
  }

  return {
    OTA_URL,
    OTA_ROOT,
    CLIENT_URL,
    USERS_URL,
  };
};

const API_CONFIG = init();

export default API_CONFIG;
