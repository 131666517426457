import "../css/Login.css";

import { useEffect, useState } from "react";
import notify from "devextreme/ui/notify";
import { Button, TextBox } from "devextreme-react";
import themes from "devextreme/ui/themes";
import api from "../api/api";
//import api from "@denisferatovic/ota-api-library";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../features/appSlice";
import CosmoLogoBlack from "../assets/Cosmo-Logo_Black.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  //api.init(process.env.REACT_APP_USERS_URL_DEV);
  const onChangeUsername = (e) => {
    setUsername(e);
  };
  const onChangePassword = (e) => {
    setPassword(e);
  };

  const showSnackbar = (severity, message) => {
    notify(message, severity);
  };

  const sendCredentials = async () => {
    try {
      if (username.length == 0 || password.length == 0) {
        showSnackbar("warning", "Please enter all fields");
        return;
      }
      const credentials = { username, password };
      // const { data } = await axios.post(`${url}/login`, credentials);
      const data = await api.postRequest(`/login`, credentials);
      if (data.accessToken) {
        localStorage.setItem("token", data.accessToken);
        localStorage.setItem("username", username);
        dispatch(
          setUser({ user: { token: data.accessToken, username: username } })
        );
        history.replace("/");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("error", "Invalid credentials");

      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    themes.current("material.purple.light.compact");
  }, []);
  return (
    <div className="login">
      <div className="dx-card">
        <img src={CosmoLogoBlack} />
        <TextBox
          showClearButton={true}
          onValueChange={onChangeUsername}
          width="100%"
          placeholder="Enter username"
        />
        <TextBox
          showClearButton={true}
          mode="password"
          onValueChange={onChangePassword}
          width="100%"
          placeholder="Enter password"
        />

        <Button
          stylingMode="contained"
          type="default"
          onClick={sendCredentials}
          text="Sign in"
          className="loginBtn"
          width="100%"
          height="15%"
        ></Button>
      </div>
    </div>
  );
};

export default Login;
