import React, { useEffect, useState } from "react";
import { Drawer } from "devextreme-react";
import Toolbar from "devextreme-react/toolbar";
import List from "devextreme-react/list.js";
import themes from "devextreme/ui/themes";
import "../css/Home.css";
import CosmoLogoWhite from "../assets/Cosmo-Logo_White.png";
import CosmoLogoBlack from "../assets/Cosmo-Logo_Black.png";
import Settings from "./Settings";
import {
  resetDrawerPages,
  selectDrawerPages,
  selectLogo,
  selectModule,
  selectTheme,
  selectUser,
  setDrawerPage,
  setLogo,
  setModule,
  setUser,
} from "../features/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  FileManagerComponent,
  createFolder,
  uploadFile,
  renameFile,
  deleteFile,
  downloadFile,
  moveFile,
  copyFile,
  clearSelection,
  refreshFileManager,
} from "@denisferatovic/ota-library";
import {
  Users,
  addUsersRow,
  showUsersColumnChooser,
  searchByTextUsers,
} from "@denisferatovic/ota-users-library";
import {
  Grid,
  Charts,
  PartnerTools,
  addClientRow,
  showClientColumnChooser,
  searchByTextClient,
  importCSV,
} from "@denisferatovic/ota-client-library";
import API_CONFIG from "../api/config";
import {
  exportMenuOptions,
  detailsLayoutMenuOptions,
} from "../toolbar/toolbarConfig";
import api from "../api/api";

const Home = ({}) => {
  const module = useSelector(selectModule);
  const user = useSelector(selectUser);
  const drawerPages = useSelector(selectDrawerPages);
  const logo = useSelector(selectLogo);
  const dispatch = useDispatch();
  const history = useHistory();
  const drawerOptions = {
    openedStateMode: "shrink",
    revealMode: "slide",
    position: "left",
  };
  const [openDrawer, setOpenDrawer] = useState(true);
  const [newFilePopupOpened, setNewFilePopupOpened] = useState(false);
  const [root, setRoot] = useState("");
  const theme = useSelector(selectTheme);

  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  };

  const username = capitalize(user.username);
  useEffect(() => {
    closeViews();
    openView(0);
    themes.current(theme);
    if (theme == "material.purple.dark.compact") {
      dispatch(setLogo({ logo: CosmoLogoWhite }));
    } else {
      dispatch(setLogo({ logo: CosmoLogoBlack }));
    }
  }, []);

  const closeViews = () => {
    dispatch(resetDrawerPages());
  };

  const openView = (index) => {
    dispatch(
      setDrawerPage({
        index,
        opened: true,
      })
    );
    dispatch(setModule({ module: { name: drawerPages[index].name } }));
  };

  const toolbarItems = [
    {
      widget: "dxButton",
      location: "before",
      options: {
        icon: "material-icons-outlined ic-menu",
        onClick: () => {
          setOpenDrawer(!openDrawer);
        },
      },
    },
    {
      widget: "dxButton",
      location: "before",
      template: `<img src='${logo}' id='cosmo-logo'/>`,
    },
    {
      text: `${module.name}`,
      location: "center",
    },
    {
      widget: "dxTextBox",
      location: "after",
      options: {
        mode: "search",
        visible: drawerPages[1].opened || drawerPages[3].opened,
        onValueChanged: (e) =>
          drawerPages[1].opened ? searchByTextClient(e) : searchByTextUsers(e),
        valueChangeEvent: "input",
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "add",
        hint:
          drawerPages[1].opened || drawerPages[3].opened
            ? "Add row"
            : "New file",
        visible:
          drawerPages[1].opened ||
          drawerPages[2].opened ||
          drawerPages[3].opened,
      },
      onClick: () => {
        drawerPages[1].opened
          ? addClientRow()
          : drawerPages[2].opened
          ? setNewFilePopupOpened(true)
          : addUsersRow();
      },
    },
    {
      widget: "dxMenu",
      location: "after",
      options: {
        stylingMode: "text",
        keyExpr: "id",
        hint: "Export",
        selectedItemKey: 1,
        items: exportMenuOptions(drawerPages[1].opened ? "client" : "users"),
        visible: drawerPages[1].opened || drawerPages[3].opened,
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "material-icons-outlined ic-import",
        hint: "Upload CSV",
        visible: drawerPages[1].opened,
      },
      onClick: (e) => {
        importCSV();
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "columnchooser",
        hint: "Column Chooser",
        visible: drawerPages[1].opened || drawerPages[3].opened,
        onClick: () => {
          drawerPages[1].opened
            ? showClientColumnChooser()
            : showUsersColumnChooser();
        },
      },
    },

    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "newfolder",
        hint: "New directory",
        visible: drawerPages[2].opened,
        onClick: () => {
          createFolder();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "material-icons-outlined ic-upload",
        hint: "Upload files",
        visible: drawerPages[2].opened,
        onClick: () => {
          uploadFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "material-icons-outlined ic-edit",
        hint: "Rename",
        visible: drawerPages[2].opened,
        onClick: () => {
          renameFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "material-icons-outlined ic-delete",
        hint: "Delete",
        visible: drawerPages[2].opened,
        onClick: () => {
          deleteFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "material-icons-outlined ic-download",
        hint: "Download",
        visible: drawerPages[2].opened,
        onClick: () => {
          downloadFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "movetofolder",
        hint: "Move",
        visible: drawerPages[2].opened,
        onClick: () => {
          moveFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "copy",
        hint: "Copy",
        visible: drawerPages[2].opened,
        onClick: () => {
          copyFile();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "clear",
        hint: "Clear selection",
        visible: drawerPages[2].opened,
        onClick: () => {
          clearSelection();
        },
      },
    },
    {
      widget: "dxButton",
      location: "after",
      options: {
        icon: "refresh",
        hint: "Refresh",
        visible: drawerPages[2].opened,
        onClick: () => {
          refreshFileManager();
        },
      },
    },
    {
      location: "after",
      widget: "dxDropDownButton",
      options: {
        displayExpr: "name",
        keyExpr: "id",
        selectedItemKey: 1,
        hint: "Details view",
        stylingMode: "text",
        useSelectMode: true,
        items: detailsLayoutMenuOptions,
        visible: drawerPages[2].opened,
      },
    },
  ];

  const NavigationList = () => {
    const navigation = [
      {
        id: 1,
        text: "Home",
        icon: "material-icons-outlined ic-home",
        onClick: () => {
          closeViews();
          openView(0);
        },
      },
      {
        id: 2,
        text: "Clients",
        icon: "material-icons-outlined ic-phone",
        onClick: () => {
          closeViews();
          openView(1);
        },
      },
      {
        id: 3,
        text: "OTA",
        icon: "material-icons-outlined ic-folder",
        onClick: () => {
          closeViews();
          openView(2);
        },
      },
      {
        id: 4,
        text: "Users",
        icon: "material-icons-outlined ic-people",
        onClick: () => {
          closeViews();
          openView(3);
        },
      },
      {
        id: 5,
        text: "Tools",
        icon: "material-icons-outlined ic-build",
        onClick: () => {
          closeViews();
          openView(4);
        },
      },
      {
        id: 6,
        text: "Settings",
        icon: "material-icons-outlined ic-settings",
        onClick: () => {
          closeViews();
          openView(5);
        },
      },

      {
        id: 7,
        text: "Help & Feedback",
        icon: "material-icons-outlined ic-help",
        onClick: () => {
          closeViews();
          openView(6);
        },
      },
      {
        id: 8,
        text: `${username}`,
        icon: "material-icons-outlined ic-user",
      },
      {
        id: 9,
        text: "Log out",
        icon: "material-icons-outlined ic-logout",
        onClick: () => {
          closeViews();
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          themes.current("material.purple.light.compact");
          dispatch(setUser({ user: { token: null, username: null } }));
          history.replace("/login");
        },
      },
    ];

    const ListItem = (data) => {
      return (
        <div className="listItem">
          <i className={data.icon}></i>
          <p>{data.text}</p>
        </div>
      );
    };
    return (
      <div>
        <div className="list">
          <List
            dataSource={navigation}
            hoverStateEnabled={false}
            activeStateEnabled={false}
            focusStateEnabled={false}
            itemRender={ListItem}
            elementAttr={{
              class: "panel-list",
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      <Toolbar items={toolbarItems} className="navBar" />
      <Drawer
        opened={openDrawer}
        openedStateMode={drawerOptions.openedStateMode}
        position={drawerOptions.position}
        revealMode={drawerOptions.revealMode}
        component={NavigationList}
        className="drawer"
      >
        <div id="content" className="dx-theme-background-color">
          {drawerPages[0].opened
            ? <Charts url={API_CONFIG.CLIENT_URL} /> || ""
            : ""}
          {drawerPages[1].opened
            ? <Grid url={API_CONFIG.CLIENT_URL} /> || ""
            : ""}
          {drawerPages[2].opened
            ? (
                <FileManagerComponent
                  url={API_CONFIG.OTA_URL}
                  newFilePopupOpened={newFilePopupOpened}
                  setNewFilePopupOpened={setNewFilePopupOpened}
                />
              ) || ""
            : ""}
          {drawerPages[3].opened
            ? <Users url={API_CONFIG.USERS_URL} /> || ""
            : ""}
          {drawerPages[4].opened
            ? <PartnerTools url={API_CONFIG.CLIENT_URL} /> || ""
            : ""}

          {drawerPages[5].opened ? <Settings /> || "" : ""}
        </div>
      </Drawer>
    </div>
  );
};

export default Home;
